.report-page {
    padding: 36px;
    flex: 1;
    display: flex;
    justify-content: center;

    iframe {
        flex: 1;
        max-width: 98vh;
        min-height: calc(100vh * 0.75);
    }
}
