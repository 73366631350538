
.customer-list-page {
	padding-bottom: 30px;
	> .card {
		margin: 0px 30px;
	}
}

.customer-detail-page {
	padding: 30px;
	> .card {
		margin: 0px 30px;
		.card-header {
			border-bottom: 2px solid rgba(0, 0, 0, 0.125);
		}
		> .card-body {
			padding: 20px 60px;
		}
	}
}


.customer-new-page {
	padding-top: 30px;
	> form > .card {
		margin: 0px 30px;
		padding: 0px 30px;
	}
	.section {
		padding: 20px 0px;
	}
	.section:not(:last-child) {

		border-bottom: solid 1px #dedede;
		.col-border {
			border-right: solid 1px #dedede;
		}
	}
}
