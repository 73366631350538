.c-alert-green {
  background-color: #CBF9CF !important;
  i {
    color: #36C344;
  }
  span {
    color: #36C344;
    font-weight: bold;
  }
}

.c-subscription-alert {
  padding: 0 28px 0 28px;
}

.c-solution-alert {
  padding: 0 30px 0 30px;
}

.mt-7px {
  margin-top: 7px;
}

.c-btn-outline-secondary {
  background-color: transparent !important;
  color: #6c757d !important;
  border: 1px solid #6c757d !important;
}

.div-primary {
  background-color: #0b85d8;
  color: #FFFFFF;
}

.div-danger {
  background-color: #ef8157;
  color: #FFFFFF;
}

.div-success {
  background-color: #6bd098;
  color: #FFFFFF;
}

.opacity-100-important {
  opacity: 1 !important;
}

.custom-radio-label {
  font-size: 15px;
  color: #252422;
}

.table-nonfluid {
  width: auto !important;
}

.discount-tag {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;

  border-left: 60px solid green;
}

.arrow-left {
  float: left;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #de0000;
}

.arrow-body {
  float :left;
  width: 82px;
  height: 24px;
  background-color: #de0000;
  padding-top: 2px;
}

.price-promotion {
  margin: 20px 0 20px 0;
  background-color: #f09a1a;
  color: white;
  padding: 5px 15px 5px 15px;
  font-weight: 700;
}

.divider {
  width: 1px;
  height: 139px;
  background-color: #ccc;
  margin: 0 40px;
}

.crop-container {
  width: 400px;
  height: 400px;
}

.react-tagsinput {
  overflow: hidden;
  padding-left: 0;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: #51bcda !important;
  border-radius: 2px;
  border: 1px solid #51bcda !important;
  color: #FFFFFF;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px 5px 5px 0;
  width: 80px;
}

.no-pointer {
  cursor: default;
}
