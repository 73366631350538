.home-page {
  padding: 30px;
  .metrics {
    & > div:not(:last-child) {
      border-right: solid 1px #d1d1d1;
    }
    i {
      font-size: 31px;
    }
    .error-message {
      color:red;
    }
  }
}
