.custom-react-table {
  &.card {
    background-color: #f4f4f4;
    padding: 53px 24px;
  }
  .ReactTable {
    .rt-table {
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.14);
      border: none;
      background-color: white;
    }
    .rt-table {
      .rt-thead {
        border-bottom: solid 1px #dedede;
        &.-filters {
          display: none;
        }
      }
      .rt-thead {
        .rt-resizable-header-content {
          padding-top:10px;
        }
      }
      .rt-tr-group {
        border-bottom: solid 1px #dedede;
        &:last-child {
          border-bottom: none;
        }
      }
      .rt-td {
        border: none;
      }
      .rt-tr {
        padding: 0 32px;
        background-color: white;
        height: 60px;
        &:hover {
          background-color: #e9f5ff;
          cursor: pointer;
        }
      }
    }
    .rt-thead {
      .rt-th:last-child {
        text-align: left;
      }
    }
  }
  .ReactTable7 {
    .rt-table {
      width: 100%;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.14);
      border: none;
      background-color: #FFF;
    }
    .rt-thead {
      .rt-rh {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        line-height: 2.5em!important;
        padding: 5px;
      }
      .rt-header {
        padding-top: 10px;
      }

      .no-sort::after {
        opacity: .6;
        content: "\F0DC";
        position: relative;
        display: inline-block;
        bottom: 0;
        right: -7px;
        font-family: "FontAwesome";
      }

      .-sort-desc::after {
        opacity: 1;
        top: -3px;
        content: "\F0DD";
        position: relative;
        display: inline-block;
        bottom: 0;
        right: -7px;
        font-family: "FontAwesome";
      }

      .-sort-asc::after {
        opacity: 1;
        top: 3px;
        content: "\F0DE";
        position: relative;
        display: inline-block;
        bottom: 0;
        right: -7px;
        font-family: "FontAwesome";
      }

    }
    .rt-tr {
      height: 60px;
      border-bottom: 1px solid #dedede;
      &:hover {
        background-color: #e9f5ff;
        cursor: pointer;
      }
    }
    .rt-td {
      padding: 0 32px;
    }

    .empty-rows {
      text-align: center;
      color: grey;
    }

  }
}
