.lead-edit-new-page {
	.card {
		margin: 0px 30px;
		padding: 0px 30px;
	}
	.lead-general-info {
		padding-top: 30px;
		.card {
			margin-bottom: 0;
		}
		.lead-status {
			width: 150px;
		}
		.lead-info-box {
			flex-direction: column;

			.lead-info {
				display: flex;
				flex-direction: row;
			}
			.lead-info-header {
				width: 30%;
				min-width: 30%;
			}
		}
	}
	.section {
		padding: 20px 0px;
	}
	.section:not(:last-child) {

		border-bottom: solid 1px #dedede;
		.col-border {
			border-right: solid 1px #dedede;
		}
	}
}

.lead-list-page {
	padding-bottom: 30px;
	> .card {
		margin: 0px 30px;
	}
}
