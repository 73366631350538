.solutions-list-page {
	min-height: 100%;
	padding: 30px;
	.card {
		cursor: pointer;
		&__description {
			color: #64676d;
		}
		&__metrics-name {
			color: #1f2533;
			font-weight: bold;
		}
		&__add-new-text {
			color: #00263f;
			font-weight: bold;
		}
	}
	.card-add {
		height: -webkit-calc(100% - 20px);
		height:    -moz-calc(100% - 20px);
		height:         calc(100% - 20px);
		min-height: 323px;
	}
	.solution-logo {
		width: 104px;
		min-width: 104px;
		height: 104px;
		object-fit: cover;
	}
}

.tab-content {
	background-color: white;
}

.page-content {
	padding: 30px;
}

.solution-detail-page {
	.info-detail {
		padding: 30px;
	}
    
	.section {
		padding:30px;
	}
    
	.section:nth-child(even) {
		background-color: #f0f0f0;
	}

	.section:nth-child(odd) {
		background-color:white;
	}

	.solution-intro {
		img {
			width: 206px;
			object-fit: cover;
		}
		.short-description {
			border-right: 1px solid #c1c1c1;
		}
	}

	.solution-description {
		.text {
			border-left: solid 5px #00a76a;
			padding-left: 29px; 
		}
	}

	.solution-features-benefits {
		&__item {
			display: flex;
			i {
				font-size: 24px;
				margin-right: 14px;
			}
		}
	}

	.solution-carousel {
		.slick-prev, .slick-next {
			font-size: 0;
			line-height: 0;
	
			position: absolute;
			top: 50%;
	
			display: block;
	
			width: 48px;
			height: 48px;
			padding: 0;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
	
			cursor: pointer;
	
			color: transparent;
			border: none;
			outline: none;
			background: transparent;
		}

		.slick-prev:before, .slick-next:before {
			font-size: 48px;
			line-height: 1;
			opacity: .75;
			color: black;
	
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		.slick-list {
			padding: 0 !important;
		}
		
		.slick-track {
			@media only screen and (max-width: 320px) {
				max-width: unset !important;
			}
		}
		
		.slick-slide {
			height: 340px;
			display: flex !important;
			align-items: center;
			justify-content: center;

			@media only screen and (max-width: 320px) {
				height: 150px;
			}

			.solution-carousel-img {
				max-width: 200px;
				min-width: 100px;
				min-height: 100px;
				//height: 150px;
				text-align: center;
				margin-left: auto;
				margin-right: auto;
				border: solid 1px #979797;
				object-fit: cover;

				@media only screen and (max-width: 320px) {
					width: 215px !important;
					height: 150px;
					margin: 0;
				}
			}
		}
		
		.slick-center {
			height: 340px;
			@media only screen and (max-width: 320px) {
				width: 235px !important;
				height: 150px;
			}
		}
		
		.slick-center img {
			max-width: 450px;
			max-height: 340px;
			@media only screen and (max-width: 320px) {
				width: 215px !important;
				height: 150px;
			}
		}        
	}
	// .solution-about-resource {
	//     // background-color: #f0f0f0;
	// }
	.dropdown-menu {
		border: 1px solid #E3E3E3;
		border-radius: 5px;
	}
}

.solution-edit-listing-page, .solution-new-listing-page {
	.card {
		padding: 30px;
		margin: 0px 30px;
	}
	.thumbnail {
		border-radius: 0px;
		img {
			width: 104px;
			object-fit: cover;
		}
	}
	.section {
		padding-bottom: 20px;
		padding-top: 0px;
	}
	.section:not(:last-child) {

		border-bottom: solid 1px #dedede;
		.col-border {
			border-right: solid 1px #dedede;
		}
	}
	.cost-options-panel {
		background-color: #e9f5ff;
		padding: 20px 30px;
		.form-group {
			margin: 0px 7px;
		}
	}

	.media-upload-error {
		border: solid 1px red;
	}

	.media-carousel {

		.media-img {
			max-width: 300px;
			@media only screen and (max-width: 576px) {
				width: 100px !important;
			}
		}

		.slick-prev, .slick-next {
			font-size: 0;
			line-height: 0;
	
			position: absolute;
			top: 50%;
	
			display: block;
	
			width: 48px;
			height: 48px;
			padding: 0;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
	
			cursor: pointer;
	
			color: transparent;
			border: none;
			outline: none;
			background: transparent;
		}

		.slick-prev:before, .slick-next:before {
			font-size: 48px;
			line-height: 1;
			opacity: .75;
			color: black;
	
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		.slick-list {
			padding: 0 !important;
		}
		
		.slick-track {
			@media only screen and (max-width: 320px) {
				max-width: unset !important;
			}
		}
		
		.slick-slide {
			display: flex !important;
			align-items: center;
			justify-content: center;
	
			@media only screen and (max-width: 576px) {
				height: 150px;
			}
	
			.media-carousel-img {
				max-width: 250px;
				min-width: 100px;
				min-height: 100px;
				text-align: center;
				margin-left: auto;
				margin-right: auto;
				border: solid 1px #979797;
	
				@media only screen and (max-width: 576px) {
					width: 100px !important;
					height: 100px;
					margin: 0;
				}
			}
		}

		.media-item-wrapper {
			width: 100%;
			&:hover {
				.media-item-front-cover {
					width: 100%;
					height: 100%;
					opacity: 0.2;
					position: absolute;
					background-color:black;
					display: block;
				}
			}
			.media-item-front-cover, .media-item-remove-icon {
				display: none;
			}
			.media-item-remove-icon {
				right : -7px;
				top: -15px;
				display: block;
				cursor: pointer;
			}
		}
	}
}

.solution-new-info-page {
	.card {
		padding: 30px;
		margin: 0px 30px;
	}
	.section {
		padding: 20px 0px;
	}
	.section:not(:last-child) {
		border-bottom: solid 1px #dedede;
	}
}

.solution-edit-feature-form {
    list-style-type: none;
    padding-left: 0;
    .solution-feature-input {
        display: block;
        div {
            display: inline;
        }
        textarea {
            width: 80%;
        }
        .form-control {
            display: inline;
            width: 95%;
        }
        .close-button {
            font-size: 1.5vw;
            border-radius: 25px;
            float: right;
            margin-top: 0;
            max-width: 2.5vw;
            min-width: 2.5vw;
            height: 2.5vw;
            text-align: center;
        }
    }
    .add-button {
        font-size: 12px;
        margin-bottom: 2%;
        text-align: left;
    }
}
