.grecaptcha-badge { display: none; }

.hidden {
	display: none;
}

.card {
	box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.14);
}

.page-content-header {
	padding: 7px 30px 20px;
}

.page-content-footer {
	padding: 22px 30px 74px;
}

.additional-resources-panel {
	background-color: #e9f5ff;
	padding: 7px 17px;
	&__select-btn {
		float: right;
		margin: 0px;
	}
	&__resource-item {
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 12px 8px 18px;
	}
	&__resource-item:not(:last-child) {
		border-bottom: solid 1px #d2d2d2;
	}
}

.saved-date-text, .gray-small-text {
	font-size: 12px;
	color: #6f6f6f;
}

.help-icon {
	margin-left: 12px;
}
