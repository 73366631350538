.login-page{
  min-height: calc(100vh - 253px);
  a:hover, a:focus {
    color: darken($primary-color, 10%);
  }
  .login-section-overlay {
    position: absolute;
    width: 100%;
    background-color: #01141c; 
    left: 0;
    top: 0;
  }
  .login-bkg-image {
    background-size: cover;
    background-position: center center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .login-section {
    & label {
      color: #b5b6ba;
    }
    .login-panel {
      background-color: #01141c;
      z-index: 1;
      .error-message {
        color:red;
      }
    }
    .header-text {
      color: $white-color;
      z-index: 1;
    }
  }
  .description-section {
    .join-btn {
      color: blue;
      font-weight: bold;
      cursor: pointer;
    }
  }
  

  .card-login{
    border-radius: 0;
    padding: 30px;

    .form-control {
      background-color: #f4f4f4;
      border-radius: 0;
      border: none;
    }

    h3 {
      color: #00263f;
    }

    h5 {
      color: #1f2533;
    }
  }

  .link{
    font-size: 10px;
    color: #6e6ed3;
    text-decoration: none;
  }
}

.full-page{
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0 120px;
  & > .footer{
    position: relative;
    z-index: 4;
  }

  &.section-image{
    position: initial;
  }

  .footer{
    position: absolute;
    width: 100%;
    bottom: 0;

    .container{
      color: $white-color;
    }
  }

  .full-page-background{
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  &:after{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
  }

  .pricing-page{
    .description{
      margin-bottom: 65px;
    }
  }

  .register-page{
    & > .container {
      margin-top: 5vh;
    }
    .info-horizontal{
      padding: 0px 0px 20px;
    }

    .info-horizontal{
      text-align: left !important;

      .icon{
        margin-top: 0;

        >i{
          font-size: 2em;
        }

        &.icon-circle{
          width: 65px;
          height: 65px;
          max-width: 65px;
          margin-top: 8px;

          i{
            display: table;
            margin: 0 auto;
            line-height: 3.5;
            font-size: 1.9em;
          }
        }
      }
      .description{
        overflow: hidden;
      }

    }
  }
}

.register-page,
.login-page,
.lock-page {
  .navbar {
    .navbar-collapse {
      .nav-item .nav-link {
        text-transform: capitalize;
        color: $white-color !important;
        i{
          margin-right: 4px;
        }
      }
    }

    &.navbar-transparent {
      border-bottom: 1px solid transparent;
      margin-top: 25px;
    }

    .navbar-brand {
      color: $white-color !important;
    }
  }
}

.register-page,
.login-page,
.lock-page {
  width: 100%;
}

@media screen and (max-width: 576px){
  .header {
    padding: 18px 22px;
  }
  .login-section-overlay {
    opacity: 0.42;
    height: 252px;
  }
  .login-bkg-image {
    background-image: url("../../img/bg/roadz-hp-hero-mobile.png");
    height: 252px;
  }
  .login-section {
    min-height: 252px;
    padding: 0px 22px 0px 22px;
    background-size: cover;
    background-position: center center;
    & label {
      color: #b5b6ba;
    }
    .login-panel {
      background-color: #01141c;
      z-index: 1;
      .error-message {
        color:red;
      }
      padding: 30px 22px 30px 22px;
    }
    .header-text {
      color: $white-color;
      padding-top: 25px;
      padding-bottom: 25px;
      font-size: 1.825em;
      line-height: 1.4;
    }
  }
  .description-section {
    padding: 28px 22px 28px;
  }
}

@media screen and (min-width: 576px){
  .header {
    padding: 38px 57px 34px 77px;
  }

  .login-bkg-image {
    background-image: url("../../img/bg/roadz-hp-hero-desktop.png");
    height: 436px;
  }

  .login-section-overlay {
    opacity: 0.22;
    height: 436px;
  }

  .login-section {
    padding: 0px 57px 0px 77px;
    min-height: 436px;
    .login-panel {
      width: 400px;
      padding: 79px 38px 30px 38px;
    }
    .header-text {
      padding-top: 79px;
      font-size: 3.1em;
      line-height: 1.15;
    }
  }

  .description-section {
    padding: 51px 77px 40px 97px;
  }
}


@media screen and (max-width: 991px){
  .register-page,
  .login-page,
  .lock-page {
    .navbar {
      &.bg-white {
        .navbar-toggler .navbar-toggler-bar.navbar-kebab {
          background: $default-color !important;
        }
        .navbar-collapse {
          .navbar-nav {
            .nav-item .nav-link {
              color: $default-color !important;
            }
          }
        }
        .navbar-brand {
          color: $default-color !important;
        }
      }

      &.navbar-transparent {
        .navbar-toggle {
          display: none;
        }
        .navbar-toggler {
          .navbar-toggler-bar.navbar-kebab {
            background: $white-color !important;
          }
        }
      }
    }
  }
}
