.footer {
  background-color: $footer-bg;
  color: $footer-color;
  .location-section {
    border-bottom: 2px solid #dedede;
    padding-bottom: 27px; 
    .location-panel {
      & > div:not(:last-child) {
        padding-right: 77px;
      }
      .location-question-section {
        border-left: 2px solid #dedede;
        padding-left: 16px;
      }
    }
  }

  &.footer-default{
    padding: 34px 70px 40px 70px;
  }

  .copyright{
    padding: 18px 0px;
  }

  &:after{
    display: table;
    clear: both;
    content: " ";
  }
}
@media screen and (min-width: 576px) {
  .footer {
    &.footer-auth {
      padding: 65px 70px 0 70px;
    }
  }
}
@media screen and (max-width: 576px) {
  .footer {
    &.footer-auth {
      padding: 39px 22px 37px;
    }
  }
}
